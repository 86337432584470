<template>
  <div class="GlobalFormDemo">
    <el-form :model="formData" inline label-width="80px" size="small" class="el-form-group_isRound">
      <el-form-item label="订单号" prop="orderNo">
        <el-input v-model="formData.orderNo" clearable placeholder="请输入订单号查询" />
      </el-form-item>
      <el-form-item label="串号" prop="imei">
        <el-input v-model="formData.imei" clearable placeholder="请输入串号查询" />
      </el-form-item>
      <el-form-item label="询价商家" prop="companyId">
        <el-select
          v-model="formData.companyId"
          placeholder="请输入或选择商家"
          filterable
          clearable
        >
          <el-option
            v-for="item in merchSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机型" prop="phoneNameShrink">
        <el-input v-model="formData.phoneNameShrink" clearable placeholder="请输入机型名称查询" />
      </el-form-item>
      <el-form-item label="下单时间" prop="timeRange">
        <el-date-picker
          v-model="formData.timeRange"
          :picker-options="pickerOptions"
          format="yyyy-MM-dd"
          value-format="timestamp"
          type="datetimerange"
          range-separator="~"
          start-placeholder="开始时间"
          end-placeholder="结束时间">
          <!-- value-format="timestamp" -->
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" :loading="serchling" round @click="handleConfirm">查询</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button size="small" type="primary" @click="$refs['reportSettingDia'].open()">修改报价配置</el-button>
      </el-form-item>
    </el-form>
    <ReportSettingDia ref="reportSettingDia" />
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
import ReportSettingDia from './dialog/ReportSettingDia.vue';
export default {
  name: 'Form',
  components: { ReportSettingDia },
  data() {
    return {
      formData: {
        machineName: '',
        channel: 'fh',
        outBrandId: '',
        timeRange: [
          moment().day(moment().day()).startOf('day').valueOf(),
          moment().endOf('day').valueOf()
        ]
      },
      pickerMinDate: null,
      day31: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day31 ||
              time.getTime() < this.pickerMinDate - this.day31
            );
          }
          return false;
        },
      },
      merchSelectList: [],
      serchling:false
    }
  },
  created(){
    this.getSelectList()
    this.handleConfirm()
  },
  methods: {
    // 所属商家下拉
    getSelectList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.merchSelectList = res.data;
        }
      });
    },
    closeSERch(){
        this.serchling=false
    },
    //查询
    handleConfirm() {
      console.log(this.formData);
      this.serchling=true
      const SeachParams = {
        ...this.formData,
        startTime: this.formData.timeRange[0],
        endTime: this.formData.timeRange[1],
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
            this.closeSERch();
        },
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    }
  }
}
</script>
