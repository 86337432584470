<template>
  <el-dialog
    title="添加规则"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="680px"
  >
    <el-form ref="formRef" :model="formData" :rules="rules" label-position="left">
      <el-form-item label="预估价区间：">
        <div style="display:flex">
          <el-form-item label-width="0" prop="lower">
            <el-input v-model="formData.lower" type="number" placeholder="请输入起始值" />
          </el-form-item>
          <div style="margin:0 10px">~</div>
          <el-form-item label-width="0" prop="upper">
            <el-input v-model="formData.upper" type="number" placeholder="请输入结束值" />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label-width="0" prop="preRatio">
        预估价 ≤ <el-input v-model="formData.preRatio" type="number" min="0" max="99" step="1" placeholder="请输入小于100的正整数" style="width: 200px" /> %时，平台需要审单
      </el-form-item>
    </el-form>
    
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "AddReportRuleDia",
  props: {
    ruleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const validateRange = (rule, value, callback) => {
      const { field } = rule
      const { lower, upper } = this.formData
      if (!lower || !upper) callback()
      if (Number(lower) >= Number(upper)) {
        if (field == 'lower') callback(new Error('起始值需小于结束值'))
        callback(new Error('结束值需大于起始值'))
      }
      callback()
    }
    const limitLenthVaild = (rule, value, callback) => {
      const number = Number(value)
      if (number !== Math.ceil(number) || number < 0 || number >= 100) {
        callback(new Error('请输入小于100的正整数'))
      }
      callback()
    }
    return {
      visible: false,
      loading: false,

      dialogType: '',
      formData: {
        lower: '',
        upper: '',
        preRatio: '',
        isOpen: false,
      },
      rules: {
        lower: [
          { required: true, message: '请输入起始值', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' }
        ],
        upper: [
          { required: true, message: '请输入结束值', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' }
        ],
        preRatio: [
          { required: true, message: '请输入范围', trigger: 'blur' },
          { validator: limitLenthVaild, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    open(row) {
      if (row) {
        this.dialogType = 'edit'
      } else {
        this.dialogType = 'add'
      }
      this.formData = row ? JSON.parse(JSON.stringify(row)) : {
        lower: '',
        upper: '',
        preRatio: '',
        isOpen: false,
      }
      this.visible = true
    },
    confirm() {
      this.$refs['formRef'].validate((vaild) => {
        if (vaild) {
          this.$emit('confirm', this.dialogType, this.formData)
          this.visible = false
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.el-date-editor.el-input{
  width: 220px !important;
}
</style>