<template>
  <div>
    <el-dialog
      title="限时报价设置"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="800px"
    >
      <div style="display:flex;align-items:center;line-height: 1">
        是否开启报价配置：
        <el-switch v-model="isOpen" active-value="01" inactive-value="02" active-text="开" inactive-text="关" inactive-color="#b6b9d0" />
      </div>
      <div class="header">
        <div>设置规则</div>
        <el-button type="primary" icon="el-icon-plus" @click="$refs['addReportRuleDia'].open()">添加规则</el-button>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        isIndex
        :isPagination="false"
        :columns="columns"
        :data="list"
      >
        <el-table-column label="预估价区间" slot="lower" align="center">
          <template slot-scope="{ row }">
            {{ row.lower }} ≤ 区间  ＜{{ row.upper }}
          </template>
        </el-table-column>
        <el-table-column label="≤预估价X%时修改报告" slot="preRatio" align="center">
          <template slot-scope="{ row }">
            {{ row.preRatio }}%
          </template>
        </el-table-column>
        <el-table-column label="启用" slot="isOpen" align="center">
          <template slot-scope="{ row }">
            <el-switch v-model="row.isOpen" active-value="01" inactive-value="02" active-text="开" inactive-text="关" inactive-color="#b6b9d0" />
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row, $index }">
            <el-link type="primary" :underline="false" style="margin-right: 10px" @click="$refs['addReportRuleDia'].open(row)">编辑</el-link>
            <el-link type="danger" :underline="false" @click="delHandler($index)">删除</el-link>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="visible = false">取 消</el-button>
        <el-button :loading="confirmLoading" size="small" type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <AddReportRuleDia ref="addReportRuleDia" :ruleList="list" @confirm="ruleHandler" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import AddReportRuleDia from './AddReportRuleDia.vue';
export default {
  components: { AddReportRuleDia },
  name: "ReportSettingDia",
  filters: {
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      confirmLoading: false,
      isOpen: '',
      list: [],
      columns: [
        { slotName: "lower" },
        { slotName: "preRatio" },
        { slotName: "isOpen" },
        { slotName: "operation" },
      ],
    };
  },
  methods: {
    open() {
      this.getConfig()
      this.visible = true
    },
    getConfig() {
      this.loading = true
      _api.findConfig().then(res => {
        const { isOpen, regionList } = res.data
        this.isOpen = isOpen
        this.list = regionList
        this.loading = false

      })
    },
    getExclusiveConfig() {
      _api.getExclusiveConfig({
        merchantId: this.row.merchantId
      }).then(res => {
        this.isLimitQuote = res.data.isLimitQuote
        this.list = res.data.rules
      })
    },
    ruleHandler(type, data) {
      if (type == 'edit') {
        const { id, _t } = data
        let i = -1
        if (id) {
          i = this.list.findIndex((item) => item.id === id)
        } else {
          i = this.list.findIndex((item) => item._t === _t)
        }
        if (i !== -1) {
          this.$set(this.list, i, data)
        }
      } else {
        this.list.push({
          ...data,
          _t: Math.random()
        })
      }
    },
    delHandler(index) {
      const item = this.list[index]
      // if (item.id) this.deleteRules.push(item.id)
      this.list.splice(index, 1)
    },
    confirm() {
      // const timeArr = this.list
      //   .map((item) => [item.limitExclusiveStart, item.limitExclusiveEnd])
      //   .sort((a, b) => a[0] < b[0] ? -1 : 1)
      // for (let i = 0; i < timeArr.length; i++) {
      //   for (let j = i + 1; j < timeArr.length; j++) {
      //     if (timeArr[i][0] < timeArr[j][0] && timeArr[j][0] < timeArr[i][1]) {
      //       this.$message.error('时间区间有重复！')
      //       return
      //     }
      //   }
      // }
      // this.confirmLoading = true
      // _api.saveExclusiveConfig({
      //   deleteRules: this.deleteRules,
      //   isLimitQuote: this.isLimitQuote,
      //   merchantIdList: this.merchantIdList,
      //   rules: this.list,
      //   saveType: this.dialogType == 'batch' ? '01' : '00'
      // }).then(res => {
      //   this.$message.success('操作成功！')
      //   this.visible = false
      //   this.$emit('confirm')
      // }).finally(() => {
      //   this.confirmLoading = false
      // })
      _api.modifyConfig({
        isOpen: this.isOpen,
        regionVos: this.list
      }).then(res => {
        this.$message.success('操作成功！')
        this.visible = false
        this.$emit('confirm')
      }).finally(() => {
        this.confirmLoading = false
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}
/*打开时文字位置设置*/
::v-deep .el-switch__label--right {
  z-index: 1;
  right: 20px;
}
/*关闭时文字位置设置*/
::v-deep .el-switch__label--left {
  z-index: 1;
  left: 20px;
}
/*显示文字*/
::v-deep .el-switch__label.is-active {
  display: block;
}
::v-deep .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 55px;
}
</style>